import React from 'react';
import contactUs from '../Assets/contactUs.jpeg';

const ContactUs = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(90vh - 64px)',
        fontFamily: 'Roboto Slab, serif',
      }}
    >
      <img
        src={contactUs}
        alt='Contact Us'
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </div>
  );
};

export default ContactUs;
