import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Outlet } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import dlaLogo from './Assets/dlaLogo.png';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const drawerWidth = 240;

const Navbar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);

  const navItems = [
    'Home',
    'Schedule & Pricing',
    'Online Registration',
    'About Us',
    'Contact Us',
    'Media Gallery',
    'Conventions',
    'Store',
  ];

  // prettier-ignore
  const navRoutes = {
    'Class Schedule': '/class-schedule',
    'Home': '/',
    'Contact Us': '/contact-us',
    'Certifications': '/certifications',
    'Staff': '/staff',
  };

  const dropdownItems = {
    'Schedule & Pricing': ['Class Schedule', 'Events', 'Summer Camps', 'Summer Classes'],
    'About Us': [
      'Birthday Parties',
      'Certifications',
      'Class Styles',
      'Competition Team',
      'Staff',
      'Facility',
    ],
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (item) => {
    if (openDropdown === item) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(item);
    }
  };

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveMenu(null);
  };

  const drawer = (
    <Box>
      <img src={dlaLogo} alt='Dla Logo' style={{ maxWidth: '200px' }} />
      <Divider style={{ fontFamily: 'Roboto Slab, serif', fontWeight: 700, color: '' }} />
      <List>
        {navItems.map((item) => (
          <div key={item}>
            {item === 'Online Registration' ? (
              <ListItemButton
                component='a'
                href='https://dancestudio-pro.com/online/index.php?account_id=15153'
                target='_blank'
                rel='noopener noreferrer'
                style={{ fontFamily: 'Roboto Slab, serif', fontWeight: 700, color: '#000000' }}
              >
                <ListItemText
                  primary={item}
                  style={{ fontFamily: 'Roboto Slab, serif', fontWeight: 700, color: '#000000' }}
                />
              </ListItemButton>
            ) : dropdownItems[item] ? (
              <>
                <ListItemButton
                  onClick={() => handleClick(item)}
                  style={{ fontFamily: 'Roboto Slab, serif', fontWeight: 700, color: '#000000' }}
                >
                  <ListItemText
                    primary={item}
                    style={{ fontFamily: 'Roboto Slab, serif', fontWeight: 700, color: '#000000' }}
                  />
                  {dropdownItems[item] ? (
                    openDropdown === item ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItemButton>
                {dropdownItems[item] && (
                  <Collapse in={openDropdown === item} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {dropdownItems[item].map((subItem) => (
                        <Link
                          to={navRoutes[subItem] || '#'} // default to '#' if no route is defined
                          key={subItem}
                          style={{ textDecoration: 'none' }}
                        >
                          <ListItem
                            button
                            style={{
                              paddingLeft: 30,
                              fontFamily: 'Roboto Slab, serif',
                              fontWeight: 700,
                              color: '#000000',
                            }}
                          >
                            <ListItemText
                              primary={subItem}
                              style={{
                                fontFamily: 'Roboto Slab, serif',
                                fontWeight: 700,
                                color: '#000000',
                              }}
                            />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </Collapse>
                )}
              </>
            ) : (
              <Link to={navRoutes[item] || '#'} style={{ textDecoration: 'none' }}>
                <ListItemButton
                  style={{ fontFamily: 'Roboto Slab, serif', fontWeight: 700, color: '#000000' }}
                >
                  <ListItemText
                    primary={item}
                    style={{ fontFamily: 'Roboto Slab, serif', fontWeight: 700, color: '#000000' }}
                  />
                </ListItemButton>
              </Link>
            )}
          </div>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component='nav' sx={{ backgroundColor: 'white' }}>
        <Toolbar sx={{ backgroundColor: 'white', justifyContent: 'space-between' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: '#000000' }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              fontFamily: 'Roboto Slab, serif',
              fontWeight: 700,
              color: '#000000',
            }}
          >
            <Typography variant='h6' noWrap sx={{ fontWeight: '900' }}>
              Dance Loud Academy
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, height: '50px', overflow: 'hidden' }}>
            <img
              src={dlaLogo}
              alt='Dla Logo'
              style={{ height: '100%', width: 'auto', objectFit: 'contain' }} // Ensure the logo stays within the Box
            />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            {navItems.map((item) =>
              item === 'Online Registration' ? (
                <Button
                  key={item}
                  href='https://dancestudio-pro.com/online/index.php?account_id=15153'
                  target='_blank'
                  rel='noopener noreferrer'
                  sx={{
                    fontFamily: 'Roboto Slab, serif',
                    fontWeight: 700,
                    color: '#000000',
                    '&:hover': { color: '#FA00BA' }, // Hover color applied immediately
                  }}
                >
                  {item}
                </Button>
              ) : dropdownItems[item] ? (
                <Box key={item}>
                  <Button
                    aria-controls='dropdown-menu'
                    aria-haspopup='true'
                    onClick={(e) => handleMenuOpen(e, item)}
                    sx={{
                      color: '#000000',
                      fontFamily: 'Roboto Slab, serif',
                      fontWeight: 700,
                      '&:hover': { color: '#FA00BA' }, // Hover color applied immediately
                    }}
                  >
                    {item}
                  </Button>
                  <Menu
                    id='dropdown-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl) && activeMenu === item}
                    onClose={handleClose}
                  >
                    {dropdownItems[item].map((dropdownItem) => (
                      <MenuItem
                        key={dropdownItem}
                        onClick={handleClose}
                        component={Link}
                        to={navRoutes[dropdownItem] || '#'}
                        sx={{
                          color: '#000000',
                          fontFamily: 'Roboto Slab, serif',
                          fontWeight: 700,
                          '&:hover': { color: '#FA00BA' }, // Hover color applied immediately
                        }}
                      >
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                <Link to={navRoutes[item] || '#'} key={item} style={{ textDecoration: 'none' }}>
                  <Button
                    sx={{
                      color: '#000000',
                      fontFamily: 'Roboto Slab, serif',
                      fontWeight: 700,
                      '&:hover': { color: '#FA00BA' }, // Hover color applied immediately
                    }}
                  >
                    {item}
                  </Button>
                </Link>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component='main' sx={{ p: 3, width: '100%' }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Navbar;
