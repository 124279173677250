import React from 'react';
import { Grid, Card, CardMedia } from '@mui/material';
import Lacy from '../Assets/Lacy.jpg';
import Paige from '../Assets/Paige.jpg';
import Gracie from '../Assets/Gracie.jpg';

const staffMembers = [
  { name: 'Lacy', image: Lacy },
  { name: 'Paige', image: Paige },
  { name: 'Gracie', image: Gracie },
];

const Staff = () => {
  return (
    <Grid container spacing={3} justifyContent='center' alignItems='center' sx={{ padding: 3 }}>
      {staffMembers.map((staff, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              borderRadius: 2,
              overflow: 'hidden',
              height: 500, // Increased height for vertically oriented images
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 3,
            }}
          >
            <CardMedia
              component='img'
              image={staff.image}
              alt={staff.name}
              sx={{
                width: 'auto', // Prioritize the height over width
                height: '100%', // Fill the card's height, maintaining the aspect ratio
                objectFit: 'contain', // Ensures the entire image is visible without cropping
              }}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Staff;
