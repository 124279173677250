import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import Home from './Pages/Home';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import ClassSchedule from './Pages/ClassSchedule';
import ContactUs from './Pages/ContactUs';
import Certifications from './Pages/Certifications';
import Staff from './Pages/Staff';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto Slab, serif',
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path='/class-schedule' element={<ClassSchedule />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/certifications' element={<Certifications />} />
          <Route path='/staff' element={<Staff />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
