import React from 'react';
import { Card, CardMedia, Grid } from '@mui/material';
import acroCertified from '../Assets/acroCertified.jpeg';

// Add your images to this array
const certificationImages = [
  { src: acroCertified, alt: 'Acro Certified' },
  // Add more images here if needed, e.g.:
  // { src: otherImage, alt: 'Other Certification' },
];

const Certifications = () => {
  return (
    <Grid
      container
      spacing={3}
      justifyContent='center'
      alignItems='center'
      sx={{ padding: 3, fontFamily: 'Roboto Slab, serif' }}
    >
      {/* Map through the certificationImages array to create cards for each image */}
      {certificationImages.map((image, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4} // Controls the size and number of items per row
          key={index}
        >
          <Card
            sx={{
              borderRadius: 2,
              height: '300px', // Controls the height of the card
              width: '300px', // Controls the width of the card
              display: 'flex',
              overflow: 'hidden', // Ensure the image fits nicely in the card
            }}
          >
            <CardMedia
              component='img'
              image={image.src}
              alt={image.alt}
              sx={{
                height: '100%',
                objectFit: 'contain', // Ensures the entire image is visible
                width: 'auto', // Ensures the entire image is visible
              }}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Certifications;
