import React from 'react';
import { Grid, Typography, Box, alpha, Paper, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import EnrollingNow from '../Assets/EnrollingNow.jpeg';
import Acro from '../Assets/Acro.jpeg';
import RSD from '../Assets/RSD.jpeg';
import hipHop from '../Assets/hipHop.jpeg';
import ComboClass from '../Assets/ComboClass.jpeg';
import dlaBanner from '../Assets/dlaBanner.jpg';
import dlaLogo from '../Assets/dlaLogo.png';

const Home = () => {
  return (
    <Grid
      container
      spacing={3}
      justifyContent='center'
      alignItems='center'
      sx={{ padding: { xs: '16px', md: '32px' }, fontFamily: 'Roboto Slab, serif' }}
    >
      {/* Logo */}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', maxHeight: '150px' }}>
        <img
          src={dlaLogo}
          alt='DLA Logo'
          style={{ width: 'auto', height: '100%', maxHeight: '150px', objectFit: 'contain' }}
        />
      </Grid>

      {/* Banner Section with Text */}
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundImage: `url(${dlaBanner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            minHeight: '10vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            backgroundRepeat: 'no-repeat',
            borderRadius: 2,
          }}
        >
          <Paper
            elevation={8}
            sx={{
              p: 4,
              borderRadius: 2,
              backgroundColor: alpha('#000000', 0.7),
              width: {
                xs: '90%',
                sm: '70%',
                md: '50%',
                lg: '40%',
              },
              textAlign: 'center',
            }}
          >
            <Typography
              variant='body1'
              sx={{
                fontWeight: 500,
                textAlign: 'justify',
                fontFamily: 'Roboto Slab, serif',
                color: '#ffffff',
                letterSpacing: '0.5px',
                lineHeight: 1.5,
                textShadow: '1px 1px 4px rgba(0, 0, 0, 0.4)',
              }}
            >
              Dance Loud Academy is a family-focused dance studio where we believe everyone, from
              ages 2 and up, can discover the joy of dance. <br /> <br />
              We offer a wide range of classes, including ballet, tap, jazz, hip hop, lyrical,
              pointe, contemporary, dance line, tumbling, and fitness. All of our students have the
              opportunity to perform, with dedicated performance teams for ages 4 and up.
              <br /> <br />
              At Dance Loud, we emphasize the purity of dance while incorporating faith and
              community outreach into everything we do.
            </Typography>
          </Paper>
        </Box>
      </Grid>

      {/* Image Cards - Ensure images fill the card */}
      {[
        { src: EnrollingNow, alt: 'Enrolling Now' },
        { src: Acro, alt: 'Acro' },
        { src: RSD, alt: 'RSD' },
        { src: hipHop, alt: 'HipHop' },
        { src: ComboClass, alt: 'Combo Class' },
      ].map((image, index) => (
        <Grid
          item
          xs={12}
          md={4}
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', // Ensure the Grid item takes full height
          }}
        >
          <Paper
            elevation={3}
            sx={{
              width: '100%',
              height: '100%', // Ensure the Paper fills its container
              borderRadius: 2,
              overflow: 'hidden',
              display: 'flex', // Flexbox ensures the image fills the Paper
            }}
          >
            <img
              src={image.src}
              alt={image.alt}
              style={{
                width: '100%',
                height: '100%', // Fill the Paper container completely
                objectFit: 'cover', // Ensures the image covers the entire area
              }}
            />
          </Paper>
        </Grid>
      ))}

      {/* Contact Info */}
      <Grid item xs={12}>
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Box display='flex' justifyContent='center'>
            {/* Phone */}
            <IconButton
              href='tel:(318) 517-3878'
              sx={{
                color: '#000000',
                margin: '0 8px',
                '&:hover': { color: '#FA00BA' }, // Hover color with no transition
              }}
            >
              <PhoneIcon fontSize='large' />
            </IconButton>

            {/* Email */}
            <IconButton
              href='mailto:danceloudacademy@gmail.com'
              sx={{
                color: '#000000',
                margin: '0 8px',
                '&:hover': { color: '#FA00BA' }, // Hover color with no transition
              }}
            >
              <EmailIcon fontSize='large' />
            </IconButton>

            {/* Facebook */}
            <IconButton
              href='https://facebook.com/DanceLoudAcademyBellaire'
              target='_blank'
              rel='noopener noreferrer'
              sx={{
                color: '#000000',
                margin: '0 8px',
                '&:hover': { color: '#FA00BA' }, // Hover color with no transition
              }}
            >
              <FacebookIcon fontSize='large' />
            </IconButton>
          </Box>

          <Typography
            variant='h6'
            sx={{
              fontWeight: 400,
              textAlign: 'center',
              fontFamily: 'Roboto Slab, serif',
              color: '#000000',
            }}
          >
            4330 Panther Dr, Bossier City, LA 71112
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
