import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import fallSchedule from '../Assets/fallSchedule.pdf';
import DLAYearlyCalendar from '../Assets/DLAYearlyCalendar.pdf';
import listSchedule from '../Assets/listSchedule.jpeg';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// Set up the PDF.js worker using import.meta.url and the correct *.mjs worker file
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const ClassSchedule = () => {
  return (
    <Grid container spacing={3} justifyContent='center' alignItems='center' sx={{ padding: 3 }}>
      {/* Fall Schedule (PDF - 1 page) */}
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant='h6' align='center'>
              Fall Schedule
            </Typography>
          </CardContent>
          <Document file={fallSchedule}>
            <Page pageNumber={1} />
          </Document>
        </Card>
      </Grid>

      {/* DLA Yearly Calendar - Page 1 */}
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant='h6' align='center'>
              DLA Yearly Calendar - Page 1
            </Typography>
          </CardContent>
          <Document file={DLAYearlyCalendar}>
            <Page pageNumber={1} />
          </Document>
        </Card>
      </Grid>

      {/* DLA Yearly Calendar - Page 2 */}
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant='h6' align='center'>
              DLA Yearly Calendar - Page 2
            </Typography>
          </CardContent>
          <Document file={DLAYearlyCalendar}>
            <Page pageNumber={2} />
          </Document>
        </Card>
      </Grid>

      {/* List Schedule (Image) */}
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardMedia
            component='img'
            image={listSchedule}
            alt='List Schedule'
            sx={{ height: 'auto', objectFit: 'contain' }}
          />
          <CardContent>
            <Typography variant='h6' align='center'>
              List Schedule
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ClassSchedule;
